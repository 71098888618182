<template>
  <!-- star -->

  <div class="kejipage_wrap">
    <nav2></nav2>
    <banner2 :bannerH="h1" :bannerImg="Img1"></banner2>
    <div class="content">
      <div class="head_title">
        <div class="title_button" :class="titleShow?'chose_btn':''" @click="titleShow=true">公司简介</div>
        <div class="title_button" :class="titleShow?'':'chose_btn'" @click="titleShow=false">专利展示</div>
      </div>
      <div v-show="titleShow">
        <div class="AboutEnterprises_cont" style="margin-top: 30px;border: 0px solid skyblue;">
          <div class="AboutEnterprises_cont_top">
            <div class="AboutEnterprises_cont_top_right">
              <img v-lazy="require('../assets/img/xcbg.png')" alt="" style="width: 549px;height: 288px;margin-top: 53px;margin-left: -50px;" />
            </div>
            <div class="AboutEnterprises_cont_top_left">
              <p>小潮出行简介</p>
              <p>
                杭州小潮出行科技有限公司坐落于浙江省杭州市西湖区，是中国飞神集团控股的子公司。公司主要立足于全球共享电动自行车、滑板车等做科技服务和运营服务，是一家集研发、生产、销售、投放（共享）智能电动自行车、电动滑板车的现代企业。主要业务为城市、景区、校园等场景的共享出行服务。 小潮出行在2022年开始致力于氢能源在共享出行和微交通领域的落地研究，公司秉承以“以人为本，科技让出行更美好”为企业发展理念，诚邀国内外科研人才加入，不断丰富氢能源出行行业的技术落地解决方案。目前在智能IOT产品、氢燃料电池、出行SASS系统上均投入了大量人力物力进行研发。小潮的使命和愿景是：给用户带来真正满意的智能化出行服务。
              </p>
            </div>

          </div>
        </div>
      </div>
      <div v-show="!titleShow">
        <div>
          <ul class="img_content">
            <li v-for="(item,index) in list" :key="index">
              <el-image class="item_img" :src="item.img[0]" :preview-src-list="item.img">
              </el-image>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <footer2></footer2>
  </div>
</template>


<script>
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav2.vue";
export default {
  name: "keji",
  components: {
    footer2,
    nav2,
    banner2,
  },
  data () {
    return {
      h1: "关于小潮",
      Img1: require("../assets/img/banner1.png"),
      titleShow: true,
      list: [
        { img: [require("../assets/img/zhuanli/0.jpg"), require("../assets/img/zhuanli/1.jpg")] },
        { img: [require("../assets/img/zhuanli/2.jpg"), require("../assets/img/zhuanli/3.jpg")] },
        { img: [require("../assets/img/zhuanli/4.jpg"), require("../assets/img/zhuanli/5.jpg")] },
        { img: [require("../assets/img/zhuanli/6.jpg"), require("../assets/img/zhuanli/7.jpg")] },
        { img: [require("../assets/img/zhuanli/8.jpg"), require("../assets/img/zhuanli/9.jpg")] },
        { img: [require("../assets/img/zhuanli/12.jpg")] },
        { img: [require("../assets/img/zhuanli/10.jpg"), require("../assets/img/zhuanli/11.jpg")] },

      ]
    };
  },
  methods: {},
};
</script>
<style scoped>
@charset "utf-8";

/*=========================Reset_start==========================*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
p,
dl,
dt,
dd,
ol,
ul,
li,
form,
table,
th,
td,
a,
img,
span,
strong,
var,
em,
input,
textarea,
select,
option {
  margin: 0;
  padding: 0;
}

/*去内外边距*/
html,
body {
  font-family: '微软雅黑', '宋体', Arail, Tabhoma;
  text-align: left;
}

/*字体 文本居左*/
ul {
  list-style: none;
}

/*去ul li 小点*/
img {
  border: 0 none;
}

/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}

/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}

/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}

/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}

/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: '';
  display: block;
  height: 0;
}

/*清浮动*/
a {
  color: #000000;
}

.kejipage_wrap {
  width: 100%;
  /* width: 1920px; */
  margin: 0 auto;
  height: 1000px;
}

/* 面包屑 */
.Crumbs {
  width: 1200px;
  height: 46px;
  margin: 0 auto;
}

.Crumbs {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
}

.Crumbs div {
  line-height: 46px;
}

.Crumbs span {
  color: #000000;
}
.content {
  /* border: 1px solid orange; */
  width: 1200px;
  margin: 0 auto;
  padding-top: 20px;
  margin-bottom: 40px;
}
.head_title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.title_button {
  border: 1px solid #afafaf;
  font-size: 24px;
  font-weight: 600;
  padding: 20px 40px;
  cursor: pointer;
}
.chose_btn {
  background: #7d94f1;
}
.AboutEnterprises {
  width: 100%;
}

.AboutEnterprises_cont {
  width: 1200px;
  margin: 0 auto;
  height: 100%;
}

.AboutEnterprises_cont_top {
  display: flex;
  justify-content: space-between;
}

.AboutEnterprises_cont_top_left {
  width: 600px;
}

.AboutEnterprises_cont_top_left p {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #000;
  line-height: 40px;
}

.AboutEnterprises_cont_top_right {
  position: relative;
  width: 487px;
  height: 517px;
}

.AboutEnterprises_cont_top_right img {
  position: absolute;
  z-index: 1;
}
.img_content {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}
.item_img {
  width: 300px;
}
</style>